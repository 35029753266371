<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else class="p-2">
      <h4>Import {{ type }}</h4>
      <b-alert v-if="importMsg.success" variant="success" show dismissible>
        <p class="p-2">{{ importMsg.success }}</p>
      </b-alert>
      <b-alert
        v-if="importMsg.errors.length > 0"
        variant="danger"
        show
        dismissible
      >
        <p class="p-2">
          Total {{ importMsg.errors.length }} {{ this.type }} are failed to
          import!
        </p>
        <b-button class="m-1" v-b-toggle.my-collapse
          ><span class="when-open">Close</span
          ><span class="when-closed">View Details </span></b-button
        >
      </b-alert>
      <b-collapse id="my-collapse">
        <b-card title="Error Details">
          <b-card-text>
            <b-list-group>
              <b-list-group-item
                variant="danger"
                v-for="(error, index) in importMsg.errors"
                :key="index"
              >
                {{ error }}
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
        </b-card>
      </b-collapse>
      <form>
        <b-row>
          <b-col
            v-if="
              type &&
              type != 'company' &&
              logedInUser.roles[0].name !== 'admin'
            "
            lg="6"
          >
            <label for="" class="label-font">Subsidiary Company:</label>
            <b-form-group>
              <v-select
                v-model="company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companies"
                label="name"
                placeholder="Select Subsidiary Company"
                @input="getCustomers"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="type && type === 'employee' && logedInUser.roles[0].name !== 'customer'" lg="6">
            <label for="" class="label-font">Client:</label>
            <b-form-group>
              <v-select
                v-model="customer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customers"
                label="name"
                placeholder="Select Customer"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="file">File <span class="text-danger">*</span></label>
            <b-form-file
              v-model="file"
              :state="fileState"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".csv, .xls, .xlsx"
            ></b-form-file>
            <span class="text-danger">We accept only csv, xls, xlxs.</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="mt-3" variant="primary" @click="importCompanies"
              >Import</b-button
            >
          </b-col>
        </b-row>
      </form>
    </div>
  </div>
</template>

<script>
import { toast } from "@/utils/toast";
import { getLogedInUser } from "@/utils/auth";
import {
  BCol,
  BRow,
  BFormFile,
  BButton,
  BSpinner,
  BAlert,
  BCard,
  BCardText,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BButton,
    BSpinner,
    BAlert,
    BCard,
    BCardText,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      file: null,
      fileState: null,
      companies: [],
      company: null,
      customers: [],
      customer: null,
      importMsg: {
        success: "",
        errors: [],
      },
      logedInUser: JSON.parse(getLogedInUser()),
    };
  },
  mounted() {
    if (this.logedInUser.roles[0].name == "admin") {
      this.company = this.logedInUser;
      this.getCustomers();
    } else if (this.logedInUser.roles[0].name == "customer") {
      this.customer = this.logedInUser;
      this.companies = this.logedInUser.customer_to_company.length > 0 ? this.logedInUser.customer_to_company : [];
    }
    if (this.type && this.type != "company" && this.logedInUser.roles[0].name == "superadmin") {
      this.getCompanies();
    }
  },
  methods: {
    getCompanies() {
      this.$store
        .dispatch("company/getCompanies")
        .then((res) => {
          this.companies = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCustomers() {
      if (this.type === "employee") {
        this.$store
          .dispatch("customer/getCompanyCustomers", {
            id: this.company.id,
          })
          .then((res) => {
            this.customers = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async importCompanies() {
      if (!this.file) {
        this.fileState = false;
        return;
      } else {
        this.loading = true;
        this.fileState = null;
        const data = new FormData();
        data.append("file", this.file);
        data.append("type", this.type);
        if (this.type && this.type != "company") {
          data.append("user_id", this.company?.id);
        }
        if (this.type && this.type === "employee") {
          data.append("customer_id", this.customer?.id);
        }
        
        await this.$store
          .dispatch("company/importCompany", data)
          .then((res) => {
            if (res.data.failed_data.length > 0) {
              const importMsg = {
                success: "",
                errors: [],
              };
              if (res.data.success > 0) {
                importMsg.success =
                  "Total " +
                  res.data.success + " " +
                  this.type +
                  " imported successfully!";
              }
              res.data.failed_data.forEach((element) => {
                let message = "";
                let name = element.row.name
                  ? element.row.name
                  : element.row.first_name + " " + element.row.last_name;
                message +=
                  "Failed to import " + this.type + " with name " + name + ".";
                message += element.error.email ? element.error.email + " " : "";
                message += element.error.phone ? element.error.phone + " " : "";
                importMsg.errors.push(message);
              });
              this.importMsg = importMsg;
            } else {
              this.importMsg = {
                success: "Total " + res.data.success + this.type + " imported!",
                errors: [],
              };
              toast("Success!", "CheckCircleIcon", "success", res.message);
              // this.type === "employee"
              //   ? this.$router.push('/employees')
              //   : this.type === "customer"
              //   ? this.$router.push('/customers')
              //   : this.$router.push('/companies');
            }
            // emit load event to parent component
            this.loading = false;
          })
          .catch((err) => {
            toast("Error!", "ErrorIcon", "danger", err.response?.data?.message || "Something went wrong!");
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
